var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/management/diet-kuesioner"
    }
  }, [_vm._v(" Manajemen Diet Kuesioner ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" Detail Diet Kuesioner ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "mb": "30px"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" Detail Diet Kuesioner ")]), _c('c-box', [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "mr": "20px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px"
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Hapus ")], 1), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "h": "auto",
      "min-w": "114px",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "as": "router-link",
      "to": {
        name: 'superadmin.diet-kuesioner.edit',
        params: {
          id: _vm.id
        }
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Edit ")], 1)], 1)], 1), _vm.item ? _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" ID Question ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.id) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kategori Question ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.category ? _vm.item.category.replace("_", " ") : "") + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Question ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.question) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Subtext (Instruksi Kepada Klien) ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.subtext) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Jenis Jawaban ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.answerType ? _vm.item.answerType.replace("_", " ") : "") + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Opsi Jawaban ")]), _vm.item.answerContent ? _c('c-list', {
    attrs: {
      "style-type": "disc"
    }
  }, _vm._l(_vm.item.answerContent, function (obj) {
    return _c('c-list-item', {
      key: obj.id,
      attrs: {
        "ml": "12px",
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(obj.answer) + " ")]);
  }), 1) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "13px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Required or Not ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_c('c-badge', {
    attrs: {
      "variant": "outline",
      "variant-color": _vm.item.isRequired ? 'red' : 'blue',
      "bg": _vm.item.isRequired ? 'rgba(234, 67, 53, 0.1)' : 'rgba(0, 117, 225, 0.1)',
      "font-size": "10px",
      "line-height": "15px",
      "border-radius": "35px",
      "py": "2px",
      "px": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.isRequired ? "REQUIRED" : "NOT REQUIRED") + " ")])], 1)], 1)], 1) : _vm._e(), _c('DeleteConfirm', {
    attrs: {
      "z-index": "1000",
      "content": _vm.deletedText,
      "is-open": _vm.isOpen
    },
    on: {
      "on-delete": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }